#header {
    background: #fff;
    box-shadow: 0 7px 22px 0 rgba(0,0,0,0.03);
    padding: 20px 0 15px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11;
    border-top: 8px solid $primary-red;
    transition: all .3s ease-in;

    @include mq(g992) {
        &.smaller {
            padding: 15px 0 10px;
            box-shadow: 0 7px 22px 0 rgba(0,0,0,0.1);

            .logo-holder img {
                width: 110px;
            }

            .nav ul li a {
                line-height: 36px;
            }
        }
    }


    @include mq(992) {
        padding: 13px 0 12px;    
    }

    .logo-holder {
        float: left;

        img {
            width: 140px;
            transition: all .3s ease-in;

            @include mq(992) {
                width: 120px;
            }
        }
    }

    .nav {
        @include clearfix();
        float: right;

        @include mq(992) {
            float: none;
            position: fixed;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: #fff;
            z-index: 11;
            padding: 21px 15px;
            transform: translateX(100%);
            transition: all .3s ease-in;
        }

        &.opened {
            transform: translateX(0);
        }

        ul li {
            display: inline-block;
            margin-left: 45px;

            &.mobile-only {
                display: none;

                @include mq(992) {
                    display: block;

                    img {
                        width: 120px;
                    }
                }
            }

            &.menu-closer {
                position: absolute;
                top: 30px;
                right: 25px;

                img {
                    width: 20px;
                }
            }

            @include mq(1400) {
                margin-left: 30px;
            }

            @include mq(992) {
                display: block;
                margin-left: 0;
                margin-bottom: 15px;
            }

            &:first-child {
                margin-left: 0;

                @include mq(992) {
                    margin-bottom: 30px;
                }
            }

            a {
                display: block;
                line-height: 45px;
                font-size: 18px;
                transition: all .2s ease-in;
                

                &.active {
                    background: #fff;
                }

                &:hover {
                    color: $primary-red;
                }
            }
        }
    }

    #burger {
        display: none;

        @include mq(992) {
            display: block;
            float: right;   

            img {
                width: 35px;
            }
        }
    }
}

#main {
    padding-top: 80px;
}

.hero {
    padding: 40px 0 20px;
    position: relative;
    height: calc(100vh - 80px);

    @include mq(992) {
        height: auto;
        padding: 40px 0;   
    }

    .half {
        float: left;
        width: 50%;
        position: relative;

        @include mq(992) {
            float: none;
            width: 100%;
        }
    }

    .mobile-img {
        display: none;

        @include mq(992) {
            display: block;
            width: 300px;
            margin: 0 auto;
        }
    }

    .headline {
        font-size: 50px;
        display: block;
        margin-bottom: 30px;

        @include mq(1500) {
            font-size: 38px
        }

        @include mq(992) {
            font-size: 32px;
            text-align: center;
        }

    }

    .buttons-holder {
        margin-bottom: 40px;

        @include mq(1500) {
            margin-bottom: 35px;
        }

        @include mq(992) {
            text-align: center;
        }

        .buttons {
            margin-bottom: 20px;

            a {
                display: inline-block;
                vertical-align: top;

                @include mq(765) { 
                    display: block;
                    margin: 0 auto 15px;
                }

                &:first-child {
                    margin-right: 30px;

                    @include mq(1500) { 
                        margin-right: 20px;
                    }

                    @include mq(765) {
                        margin-right: auto;
                    }
                }
            }
        }

        span {
            display: block;
            text-align: center;
            font-size: 14px;

        }
    }

    .caption {
        display: block;
        font-size: 38px;
        font-weight: $medium;
        margin-bottom: 20px;

        @include mq(1500) { 
            margin-bottom: 30px;
            font-size: 30px;
        }

        @include mq(992) {
            font-size: 28px;
        }
    }

    ul {
        li {
            font-size: 17px;
            color: $text-gray;
            font-style: italic;
            line-height: 1.1;
            margin-bottom: 20px;

            @include mq(1500) { 
                font-size: 16px;
                margin-bottom: 15px;
            }
        }

        @include mq(992) {
            margin-bottom: 60px;
        }
    }

    .big-img {
        transform: translateX(100px);

        @include mq(992) {
            display: none;
        }

        

        img {
            width: 90%;

            @include mq(1500) {
                width: 80%;
            }
        }
    }

    .scroll-down {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%,0);
        text-align: center;

        @include mq(992) {
            position: relative;
            left: initial;
            bottom: initial;
            transform: none;
        }

        span {
            display: block;
            font-weight: $medium;
            font-size: 24px;
            margin-bottom: 15px;

            @include mq(1500) { 
                font-size: 18px;
            }

            @include mq(1280) {
                margin-bottom: 10px;
            }
        }

        img {
            transition: all .3s ease;

            @include mq(1280) { 
                width: 30px;
            }

            @include mq(992) {
                margin: 0 auto;
                display: block;
            }
        }

        &:hover {
            cursor: pointer;

            img {
                transform: translateY(5px);
            }
        }
    }
}

.home-section {
    padding: 90px 0;
    // background: linear-gradient(180deg, rgba(247,247,247,1) 0%, rgba(0,212,255,0) 30%);
    background: rgba(247,247,247,1);
    background: -moz-linear-gradient(top, rgba(247,247,247,1) 0%, rgba(255,255,255,0) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(247,247,247,1)), color-stop(100%, rgba(255,255,255,0)));
    background: -webkit-linear-gradient(top, rgba(247,247,247,1) 0%, rgba(255,255,255,0) 100%);
    background: -o-linear-gradient(top, rgba(247,247,247,1) 0%, rgba(255,255,255,0) 100%);
    background: -ms-linear-gradient(top, rgba(247,247,247,1) 0%, rgba(255,255,255,0) 100%);
    background: linear-gradient(to bottom, rgba(247,247,247,1) 0%, rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#ffffff', GradientType=0 );


    @include mq(1280) {
        padding: 70px 0;
    }

    @include mq(992) {
        padding: 50px 0   
    }

    .section-top {
        text-align: center;

        h3 {
            font-size: 40px;
            font-weight: $medium;
            margin-bottom: 35px;
            position: relative;

            @include mq(1280) { 
                font-size: 28px;
            }

            @include mq(992) {
                font-size: 24px;
            }

            &:after {
                content: '';
                width: 320px;
                height: 1px;
                position: absolute;
                left: 50%;
                bottom: -15px;
                transform: translate(-50%,0);
                background: #D8D8D8;
            }
        }

        p {
            font-size: 24px;
            color: $text-gray;
            line-height: 1.5;

            @include mq(1280) { 
                font-size: 20px;
            }

            @include mq(992) {
                font-size: 18px;
            }
        }
    }

    .blocks {
        @include clearfix();
        margin: 0 -16px;

        @include mq(765) { 
            margin: 0;
        }
    }

    .quarter {
        width: 25%;
        float: left;
        padding: 0 20px;
        text-align: center;
    }

    .third {
        width: 33.33%;
        float: left;
        padding: 0 20px;
        text-align: center;

        @include mq(765) { 
            width: 100%;
            float: none;
            margin-bottom: 35px;
        }
    }
}

.function {
    .section-top {
        margin-bottom: 110px;

        @include mq(1280) { 
            margin-bottom: 70px;
        }

        @include mq(992) {
            margin-bottom: 60px;
        }
    }

    .blocks {
        @include mq(765) {
            position: relative;

            .slick-dots {
                bottom: -40px;
            }

            .slick-dots button:before {
                font-size: 35px;
            }
        }

        .quarter {

            @include mq(992) {
                padding: 0 10px;
            }

            .no {
                display: block;
                font-size: 25px;
                color: $text-gray;
                margin-bottom: 5px;
                font-weight: $bold;
            }

            .caption {
                font-size: 20px;
                display: block;
                line-height: 1.5;
                height: 70px;
                color: $text-gray;

                @include mq(1280) { 
                    font-size: 18px;
                    line-height: 1.5;
                    height: 60px;
                }

                @include mq(992) {
                    font-size: 16px;
                }
            }

            img {
                width: 100%;

                @include mq(765) {
                    max-width: 240px;
                    margin: 0 auto;
                }
            }
        }
    }
}

.security {
    .section-top {
        margin-bottom: 100px;

        @include mq(1280) { 
            margin-bottom: 70px;
        } 
    }

    .blocks {

        &.first {
            margin-bottom: 120px;

            @include mq(1280) { 
                margin-bottom: 70px;
            }
        }

        .third {
            .icon-holder {
                width: 100%;
                height: 75px;
                margin-bottom: 10px;
                position: relative;

                @include mq(1280) { 
                    height: 60px;
                }

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);

                    @include mq(1280) { 
                        max-height: 80%;
                    }
                }
            }

            .headline {
                display: block;
                font-size: 22px;
                font-weight: $semi-bold;
                margin-bottom: 25px;
                text-transform: uppercase;;

                @include mq(1280) { 
                    font-size: 20px;
                }

                @include mq(992) {
                    font-size: 18px;
                    margin-bottom: 15px;
                }
            }

            .text {
                display: block;
                font-size: 20px;
                color: $text-gray;
                line-height: 1.2;

                @include mq(1280) {
                    font-size: 18px;
                 }

                 @include mq(992) {
                    font-size: 16px;
                }

            }

        }
    }
    .landscape-img {
        margin-bottom: 100px;
        text-align: center;

        @include mq(1280) { 
            margin-bottom: 60px;
        }

        @include mq(1280) { 
            img {
                width: 100%;
            }
        }
    }

}

.webapp {
    .section-top {
        margin-bottom: 150px;

        @include mq(1280) { 
            margin-bottom: 80px;
        }
    }

    .blocks {
        .half {
            float: left;
            width: 50%;

            @include mq(765) { 
                width: 100%;
                float: none;
            }

            .caption {
                padding-top: 50px;
                display: block;
                font-size: 38px;
                font-weight: $medium;
                margin-bottom: 20px;

                @include mq(1280) { 
                    padding-top: 0;
                    font-size: 28px;
                }

                @include mq(992) { 
                    font-size: 24px;
                }

                
            }
        
            .text {
                font-size: 19px;
                color: $text-gray;
                font-style: italic;
                margin-bottom: 20px;
                line-height: 1.9;
                padding-right: 70px;

                @include mq(1280) { 
                    line-height: 1.4;
                }

                a {
                    font-weight: $bold;
                    display: block;
                }

                @include mq(992) { 
                    font-size: 17px;
                    padding-right: 10px;
                }
            }
        }
    }

    .big-img {
        img {
            @include mq(1500) {
                width: 130%;
            }

            @include mq(765) { 
                width: 100%;
            }
        }
    }
}

.pricing {
    .section-top {
        margin-bottom: 140px;

        @include mq(1280) { 
            margin-bottom: 80px;
        }

        @include mq(765) { 
            margin-bottom: 70px;
        }
    }

    .pricing-boxes {
        @include clearfix();
        margin: 0 -8px;

        @include mq(765) {
            margin: 0 0 40px;
            position: relative;

            .slick-dots {
                bottom: -45px;
            }

            .slick-dots button:before {
                font-size: 35px;
            }
        }

        .pricing-box-holder {
            float: left;
            width: 33.33%;
            padding: 0 8px;

            .pricing-box {
                border-radius: 20px;
                padding: 30px 40px 0;
                text-align: center;
                // background: linear-gradient(180deg, rgba(247,247,247,1) 0%, rgba(0,212,255,0) 100%);
                background: rgba(247,247,247,1);
                background: -moz-linear-gradient(top, rgba(247,247,247,1) 0%, rgba(255,255,255,0) 100%);
                background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(247,247,247,1)), color-stop(100%, rgba(255,255,255,0)));
                background: -webkit-linear-gradient(top, rgba(247,247,247,1) 0%, rgba(255,255,255,0) 100%);
                background: -o-linear-gradient(top, rgba(247,247,247,1) 0%, rgba(255,255,255,0) 100%);
                background: -ms-linear-gradient(top, rgba(247,247,247,1) 0%, rgba(255,255,255,0) 100%);
                background: linear-gradient(to bottom, rgba(247,247,247,1) 0%, rgba(255,255,255,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#ffffff', GradientType=0 );


                @include mq(1280) { 
                    padding: 30px 20px 10px;
                }

                .headline {
                    display: block;
                    font-size: 20px;
                    font-weight: $bold;
                    margin-bottom: 45px;

                    @include mq(765) { 
                        margin-bottom: 35px;   
                    }
                }

                .price {
                    display: block;
                    font-size: 65px;
                    margin-bottom: 5px;;

                    @include mq(1280) { 
                        font-size: 45px;
                    }
                }

                .notice {
                    font-size: 15px;
                    color: $primary-red;
                    margin-bottom: 50px;
                    display: block;

                    @include mq(765) { 
                        margin-bottom: 35px;   
                    }
                }

                ul {
                    margin-bottom: 50px;

                    li {
                        font-size: 16px;
                        color: $text-gray;
                        margin-bottom: 15px;
                        line-height: 1.1;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        @include mq(765) {
                            font-size: 15px;
                        }
                    }
                }

                .site-btn {
                    margin: 0 auto;
                }
            }
        }
    }
}

#footer {
    background: #2B2B2B;
    padding: 110px 0 30px;
    color: #fff;
    text-align: center;

    @include mq(1280) {
        padding: 60px 0 70px;
     } 

     .modal-openers {

         p {
             display: inline-block;
             margin: 0 10px;
             font-size: 14px;

             &:hover {
                 cursor: pointer;
                 text-decoration: underline;
             }
         }
     }

    .headline {
        font-size: 25px;
        display: block;
        margin-bottom: 50px;

        @include mq(765) { 
            font-size: 20px;
            margin-bottom: 35px;
        }
    }

    .icons {
        text-align: center;
        margin-bottom: 75px;

        a {
            display: inline-block;
            margin: 0 10px;

            @include mq(765) { 
                display: block;
                margin-bottom: 15px;
            }
        }
    }

    .newsletter {
        .caption {
            font-size: 25px;
            display: block;
            margin-bottom: 10px;

            @include mq(765) { 
                font-size: 20px
            }
        }

        .text {
            font-size: 15px;
            display: block;
            margin-bottom: 60px;
        }

        .inputs {
            text-align: center;

            input {
                display: inline-block;
                vertical-align: top;
                margin: 0 5px;
            }

            input[type="text"] {
                width: 390px;
                height: 47px;
                border-radius: 12px;
                border: 1px solid #979797;
                color: #fff;
                padding: 0 16px;
                font-weight: $regular;
                font-size: 18px;
                font-family: 'Montserrat', sans-serif;
                background: transparent;
                outline: none;

                @include mq(765) { 
                    width: 100%;
                    display: block;
                    margin-bottom: 15px;
                }
            }

            input[type="submit"] {
                background: #EFEFEF;
                width: 160px;
                height: 47px;
                color: $text-gray;
                font-size: 16px;
                border-radius: 12px;
                border: none;
                -webkit-transition: all 0.15s ease-in;

                @include mq(765) { 
                    display: block;
                    margin: 0 auto;
                }


                &:hover {
                    cursor: pointer;
                    color: #000;
                }
            }
        }
    }

    .bottom {
        margin-top: 80px;
        display: flex;
        justify-content: space-between;

        @include mq(765) {
            display: block;
        }
    }

    .copyright {
        // margin-top: 80px;
        font-size: 12px;

        @include mq(765) {
            margin-bottom: 15px;
        }
    }
}


.modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(#fff, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
    transition: all .2s linear;
    
    &.modal-closed {
        opacity: 0;
        z-index: -1;
    }

    @include mq(765) {
        display: block;       
    }

    .modal-cnt-wrap {
        position: absolute;

        @include mq(480) {
            width: 100%;
        }
    }



    .modal-cnt {
        background: #fff;
        border: 1px solid #ccc;
        padding: 30px;
        width: 500px;
        position: relative;
        max-height: 500px;

        @include mq(765) {
            width: 80%;
            margin: 40px auto;

        }

        @include mq(480) {
            width: 310px;
            padding: 25px 20px;
         }
        

        &.terms-modal {
            overflow-y: scroll;
        }

        span {
            display: block;
            font-size: 12px;
            line-height: 1.3;
            margin-bottom: 15px;

            &.big {
                font-size: 14px;
            }
        }

        
    }
    img {
        width: 15px;
        position: absolute;
        top: -20px;
        right: -20px;

        &:hover {
            cursor: pointer;
        }

        @include mq(765) {
            top: 15px;
            right: 15px;
        }
    }
}