//// Mixins
//media queries 
@mixin mq( $width ) {
    @if $width==g992 {
		@media ( min-width: 992px ) {
			@content;
		}
    }
    @if $width==1500 {
		@media ( max-width: 1500px ) {
			@content;
		}
    }
    @if $width==1400 {
		@media ( max-width: 1400px ) {
			@content;
		}
	}
    @if $width==1280 {
		@media ( max-width: 1280px ) {
			@content;
		}
    }
    
    @if $width==992 {
		@media ( max-width: 992px ) {
			@content;
		}
    }
    
    @if $width==765 {
		@media ( max-width: 765px ) {
			@content;
		}
    }

    @if $width==480 {
		@media ( max-width: 480px ) {
			@content;
		}
	}
    
    @if $width==360 {
		@media ( max-width: 360px ) {
			@content;
		}
	}
	
}

//placeholder
@mixin inputPlaceholder() {
    $selector: '';
    $prefixes: (
        moz: "::-moz",
        webkit: "::-webkit",
        ie: ":-ms"
    );
    @each $prop, $value in $prefixes {
        @if $prop != "moz" {
            $selector: #{$value}-input-placeholder;
        } @else {
            $selector: #{$value}-placeholder;
        }
        @if & {
            &#{$selector} {
                @content;
            }
        } @else {
            #{$selector} {
                @content;
            }
        }
    }
    &::placeholder {
      @content;
    }
}

//clearfix
@mixin clearfix() {
    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }
}
