// Variables
// Font weights
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;

// Colors
$primary-red: #EA0201;
$primary-blue: #8e96ae;
$text-gray: #898989;