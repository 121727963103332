// RESET
body,
html {
    padding: 0;
    margin: 0;
}
p {
    margin: 0;
}
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}
a {
    text-decoration: none;
    color: inherit;
}

// Settings
body {
    font-family: 'Montserrat', sans-serif;
    font-weight: $regular;
    color: #000;
}
#wrapper {
    
    overflow: hidden;
}
.container {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
    
    @include mq(1280) {
        width: 960px;
    }

    @include mq(992) {
        width: 720px;
    }

    @include mq(765) {
        width: 100%;
    }

    @include clearfix();
}

.site-btn {
    width: 260px;
    height: 58px;
    border: 2px solid $primary-red;
    background: #fff;
    color: $primary-red;
    border-radius: 38px;
    font-weight: $semi-bold;
    font-size: 17px;
    text-align: center;
    line-height: 58px;
    display: block;
    transition:         0.12s ease-in;
    -o-transition:      0.12s ease-in;
    -ms-transition:     0.12s ease-in;
    -moz-transition:    0.12s ease-in;
    -webkit-transition: 0.12s ease-in;
    position: relative;
    overflow: hidden;
    z-index: 1;

    @include mq(1400) { 
        width: 210px;
        height: 44px;
        line-height: 44px;
        font-size: 16px;
    }

    &:before {
        position:absolute;  
        content:"";
        background: $primary-red;
        width:100%;
        height:100%;
        top:0;
        left:0;
        z-index: -1;
        opacity: 1;
        border-radius: 38px;
        -webkit-transition: top 0.15s ease-in;
        top: 100%;
    }



    &:hover {
        color: #fff;
        cursor: pointer;

        &:before {
            top: 0;
        }
    }
}